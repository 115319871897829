import * as React from "react";
import { Button, TextField, Container, Typography } from "@mui/material";
import "../style/alt.scss";
import Logo from "../images/mdaxxlogo.png";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Link } from "gatsby-theme-material-ui";

// markup
const IndexPage = () => {
  return (
    <>
      <Container id="mainContent" maxWidth="lg" className="mainWrapper">
        <img className="logo" src={Logo} />
        <div className="buttonWrapper">
          <Link className="mdxButton" to="/workfor">
            Work for Us
          </Link>
          <Link className="mdxButton" to="/workwith">
            Work with Us
          </Link>
        </div>
      </Container>
    </>
  );
};

export default IndexPage;
